
































































































































































import { Component, Vue, Watch } from 'vue-property-decorator';
import BaseMultiSelect from '@/jbi-shared/vue-components/BaseMultiSelect.vue';
import Container from '@/components/Container.vue';
import {
  ValidationObserver,
  ValidationObserverInstance,
  ValidationProvider
} from 'vee-validate';
import AgreementSection from '@/views/AdminCreateGroup/components/AgreementSection.vue';
import GroupCreationSubmitButtons from '@/views/AdminCreateGroup/components/GroupCreationSubmitButtons.vue';
import GroupAttributeForm from '@/views/AdminCreateGroup/components/GroupAttributeForm.vue';
import GroupNameAndTypeForm from '../AdminCreateGroup/components/GroupNameAndTypeForm.vue';
import GroupSettingsMembersSection from '@/views/AdminCreateGroup/components/GroupSettingsMembersSection.vue';
import ExistingMembersSelector from '@/views/AdminCreateSubGroup/components/ExistingMembersSelector.vue';
import {
  attributesHasChanged,
  hasSameAttributesAsParent,
  MemberObject,
  userAttributesArrWithValues
} from '@/utils/group.util';
import { GetSignedUrlForUploadResponsePayload } from '@/store/modules/static-file/types/static-file.types';
import {
  ExportGroupMembersCsvPayload,
  GroupDetail
} from '@/store/modules/admin/types/admin.types';
import { Action, State } from 'vuex-class';
import {
  MyjbiGroupDetail,
  MyjbiGroupType,
  MyjbiGroupUserAttributeSpec
} from '@/jbi-shared/types/myjbi-group.types';
import { RootState } from '@/store/store';
import { cloneDeep } from 'lodash';
import { DialogProgrammatic, ToastProgrammatic as Toast } from 'buefy';
import {
  RemoveEmailDomainsPayload,
  UpdateGroupRequestPayload,
  UserAttributesStringInputOption
} from '@/store/modules/admin/types/group.types';
import { GroupUserAttributeWithValue } from '@/store/modules/admin/types/group-user-attribute.types';
import { isDifferent, isTruthy } from '@/jbi-shared/util/watcher.vue-decorator';
import delay from 'delay';
import { defaultAttributes } from '../AdminCreateGroup/AdminCreateGroup.vue';
import EmailDomainForm from '@/views/AdminCreateGroup/components/EmailDomainForm.vue';
import RemoveEmailDomainModal from '@/views/AdminCreateGroup/components/RemoveEmailDomainModal.vue';
import GroupLevelAttribute from './components/GroupLevelAttribute.vue';
import UserAttributeTab from '../AdminCreateGroup/components/UserAttributeTab/UserAttributeTab.vue';
import {
  PermissionsMatrixActionsEnum,
  ResourceExceptions
} from '@/store/modules/roles-and-permissions/types/roles-and-permissions.types';
import { isUserAllowed } from '@/utils/rbac.util';
import { EntityTypes } from '@/store/modules/module-tree/enums/module-tree.enums';
import {
  AttributeOptionsWithValue,
  GroupLevelAttributeValue
} from '@/jbi-shared/types/jaas-group-level-attributes.types';
import { UpdateGroupLevelAttributeValueDTO } from '@/jbi-shared/dto/jaas-group-level-attributes.dtos';
import { ApiState } from '@/store/types/general.types';

export let emailDomain: string[] = [];

@Component({
  computed: {
    PermissionsMatrixActionsEnum() {
      return PermissionsMatrixActionsEnum;
    }
  },
  components: {
    Container,
    BaseMultiSelect,
    AgreementSection,
    ValidationObserver,
    ValidationProvider,
    GroupAttributeForm,
    GroupNameAndTypeForm,
    GroupCreationSubmitButtons,
    GroupSettingsMembersSection,
    ExistingMembersSelector,
    EmailDomainForm,
    GroupLevelAttribute,
    UserAttributeTab
  }
})
export default class AdminGroupSettings extends Vue {
  isSettings = true;
  isAddingConfirmed = false;
  notify = false;
  existingEmailDomains: string[] = [];
  isMemberDataValid = true;
  allowedEmailDomains: string[] = [];
  removedEmailDomains: RemoveEmailDomainsPayload[] = [];
  removedMemberIds: number[] = [];
  initialDomains: string[] = [];
  currentTab: number = 0;

  $style!: Record<string, string>;
  name = '';
  types: UserAttributesStringInputOption[] = [];
  userAttributes: MyjbiGroupUserAttributeSpec[] = [...defaultAttributes];
  agreementFiles: Array<
    File | Partial<GetSignedUrlForUploadResponsePayload>
  > = [];
  membersFile: File | null = null;
  membersData: MemberObject[] | null = null;
  isDownloading = false;

  selectedGroupLevelAttributes: GroupLevelAttributeValue[] | null = null;
  activeGroupAttributes: GroupLevelAttributeValue[] = [];
  deleteGroupAttributes: GroupLevelAttributeValue[] = [];

  groupLevelAttributeFormHasError: boolean = false;
  isGroupLevelAttributeLoading: boolean = false;
  parentUserAttributes: MyjbiGroupUserAttributeSpec[] = [...defaultAttributes];

  /* Actions and State: Groups */
  @Action('admin/getGroupDetail')
  getGroupDetail!: (params: {
    id: number;
    isMembers: boolean;
  }) => Promise<GroupDetail>;

  @Action('admin/getParentGroupDetailByChildGroupId')
  getParentGroupDetailByChildGroupId!: (
    id: number
  ) => Promise<MyjbiGroupDetail>;

  @Action('admin/updateGroup')
  updateGroup!: (payload: UpdateGroupRequestPayload) => Promise<void>;

  @Action('admin/deleteGroup')
  deleteGroup!: (id: number) => Promise<void>;

  @Action('rolesAndPermissions/getGroupExceptions')
  getGroupExceptions!: (groupId: number) => Promise<ResourceExceptions>;

  @State((state: RootState) => state.rolesAndPermissions.groupExceptions)
  groupExceptions!: ResourceExceptions;

  @State((state: RootState) => state.admin.groupDetail)
  groupDetail!: GroupDetail;

  @State((state: RootState) => state.admin.apiState.updateGroup.loading)
  updateGroupLoading!: boolean;

  @State((state: RootState) => state.admin.apiState.deleteGroup.loading)
  deleteGroupLoading!: boolean;

  /* Actions and State: Group User Attributes (Members) */
  @Action('admin/exportCsvGroupMembersList')
  public exportCsvGroupMembersList!: (
    params: ExportGroupMembersCsvPayload
  ) => Promise<void>;

  @Action('admin/getMasterGroupEmailDomains')
  getMasterGroupEmailDomains!: (id: number) => Promise<string[]>;

  @Action('admin/removeEmailDomain')
  removeEmailDomain!: ({
    groupId,
    emailDomain,
    notify
  }: {
    groupId: number;
    emailDomain: string | null; // setting null to indicate delete all domains
    notify: boolean;
  }) => Promise<void>;

  /* Actions and State: Group Level Attribute Values */
  @Action('admin/updateGroupLevelAttributeValues')
  updateGroupLevelAttributeValues!: (
    payload: UpdateGroupLevelAttributeValueDTO
  ) => Promise<void>;

  @State(
    (state: RootState) => state.admin.apiState.updateGroupLevelAttributeValues
  )
  updateGroupLevelAttributeValuesApiState!: ApiState;

  public isUserAllowed(
    action: PermissionsMatrixActionsEnum,
    module: string | string[],
    skipImplicitCheck?: boolean
  ): boolean {
    const instance = EntityTypes.GROUP + '_' + this.groupId;
    const groupTypeName = this.groupDetail
      ? this.groupDetail.types[0]
      : undefined;
    return this.isSettings
      ? isUserAllowed(
          action,
          module,
          EntityTypes.GROUP,
          groupTypeName,
          this.groupId,
          this.groupExceptions,
          skipImplicitCheck
        )
      : true;
  }

  get PermissionsMatrixActionsEnum() {
    return PermissionsMatrixActionsEnum;
  }

  get groupDetailMembers() {
    return this.groupDetail.members ?? [];
  }

  get isUserLevelAttribute() {
    return (
      this.isUserAllowed(PermissionsMatrixActionsEnum.CREATE, [
        'group_administration-groups-update_groups-update_user_attributes-create_existing_user_attributes',
        'group_administration-groups-update_groups-update_user_attributes-create_new_user_attributes'
      ]) ||
      this.isUserAllowed(
        PermissionsMatrixActionsEnum.READ,
        'group_administration-groups-update_groups-update_user_attributes-read_existing_user_attributes'
      ) ||
      this.isUserAllowed(
        PermissionsMatrixActionsEnum.DELETE,
        'group_administration-groups-update_groups-update_user_attributes-delete_added_user_attributes'
      )
    );
  }

  get isGroupLevelAttribute() {
    return (
      this.isUserAllowed(PermissionsMatrixActionsEnum.UPDATE, [
        'group_administration-groups-update_groups-update_group_attributes-update_default_attributes',
        'group_administration-groups-update_groups-update_group_attributes-update_existing_attributes',
        'group_administration-groups-update_groups-update_group_attributes-create_group_attributes'
      ]) ||
      this.isUserAllowed(
        PermissionsMatrixActionsEnum.READ,
        'group_administration-groups-update_groups-update_group_attributes-read_existing_group_templates'
      )
    );
  }

  get isLocalOrDev() {
    return (
      process.env.VUE_APP_ENV === 'local' ||
      process.env.VUE_APP_ENV === 'development'
    );
  }

  get masterGroupEmailDomains() {
    return (this.$store.state as RootState).admin.masterGroupEmailDomains;
  }

  get parentsName(): string[] {
    return this.groupDetail?.parentsName ? this.groupDetail?.parentsName : [];
  }
  get isProtected(): boolean {
    return this.groupDetail?.protectedTypes?.isProtected
      ? this.groupDetail?.protectedTypes?.isProtected
      : false;
  }

  get parentGroupDetailByChildGroupId() {
    return (this.$store.state as RootState).admin
      .parentGroupDetailByChildGroupId;
  }

  get getParentGroupDetailLoading() {
    return (this.$store.state as RootState).admin.apiState
      .getParentGroupDetailByChildGroupId.loading;
  }

  get getParentGroupDetailSuccess() {
    return (this.$store.state as RootState).admin.apiState
      .getParentGroupDetailByChildGroupId.success;
  }

  get groupId() {
    return +this.$route.params.groupId;
  }

  get getGroupDetailLoading() {
    return (this.$store.state as RootState).admin.apiState.getGroupDetail
      .loading;
  }

  get pageIsLoading(): boolean {
    return (
      !this.groupDetail ||
      this.deleteGroupLoading ||
      this.updateGroupLoading ||
      this.updateGroupLevelAttributeValuesApiState.loading
    );
  }

  get hasSameAttributesAsParent() {
    return hasSameAttributesAsParent(
      this.userAttributes,
      this.parentGroupDetailByChildGroupId
    );
  }

  get attributesHasChanged() {
    return attributesHasChanged(this.userAttributes, this.groupDetail);
  }

  get canSubmit(): boolean {
    return !this.isMemberTableVisible || this.isMemberDataValid;
  }

  get isMemberTableVisible(): boolean {
    return !this.hasSameAttributesAsParent || this.attributesHasChanged;
  }

  removedMembers(memberEmail: string) {
    const removedMember = this.groupDetail?.members?.find(
      (member) => member.attributes.email === memberEmail
    );
    if (removedMember) {
      this.removedMemberIds.push(removedMember.userId);
    }
  }

  groupSettingsHasError(isValidationObserverInvalid: boolean): boolean {
    return (
      isValidationObserverInvalid ||
      this.groupLevelAttributeFormHasError ||
      !this.canSubmit
    );
  }

  handleUpdateGroupLevelAttributeValues(
    activeValues: GroupLevelAttributeValue[],
    deleteValues: GroupLevelAttributeValue[],
    error: boolean
  ) {
    this.activeGroupAttributes = activeValues;
    this.deleteGroupAttributes = deleteValues;
    this.groupLevelAttributeFormHasError = error;
  }

  get groupName() {
    return this.groupDetail?.name;
  }

  get nlevel() {
    return this.groupDetail?.nlevel;
  }

  get isMasterGroup() {
    return this.nlevel === 1;
  }

  get parentGroupId() {
    return this.parentGroupDetailByChildGroupId?.id;
  }

  public getEmailDomains() {
    // get email domains from current group details
    const emailDomainFromCurrentId = this.groupDetail?.emailDomains;

    // get email domains from root ancestor
    const emailDomainFromAncestor = this.masterGroupEmailDomains;

    // if this group detail has email domains, use that
    if (emailDomainFromCurrentId && emailDomainFromCurrentId.length > 0) {
      return emailDomainFromCurrentId;
      // if not, use the one you get from root ancestor (master group)
    } else if (emailDomainFromAncestor && emailDomainFromAncestor.length > 0) {
      return emailDomainFromAncestor;
    } else {
      return [];
    }
  }

  get isAddingFirstDomain() {
    const intersectionElements = this.allowedEmailDomains.filter((domain) =>
      this.initialDomains.includes(domain)
    );
    if (this.allowedEmailDomains.length === 0) {
      return false;
    } else {
      return intersectionElements.length > 0 ? false : true;
    }
  }

  public handleAddEmailDomain(tag: string) {
    this.allowedEmailDomains = [...this.allowedEmailDomains, tag];
    if (this.existingEmailDomains.includes(tag)) {
      this.removedEmailDomains = this.removedEmailDomains.filter(
        (removedDomain) => removedDomain.tag !== tag
      );
    }
  }

  public async handleRemoveEmailDomain(event: RemoveEmailDomainsPayload) {
    if (event.tag === null) {
      this.allowedEmailDomains = [];
      this.removedEmailDomains = [event];
    } else {
      if (this.existingEmailDomains.includes(event.tag)) {
        this.removedEmailDomains = [...this.removedEmailDomains, event];
      }
      this.allowedEmailDomains = this.allowedEmailDomains.filter(
        (domain) => domain !== event.tag
      );
    }
  }

  public confirmAdd() {
    this.$buefy.modal.open({
      parent: this,
      component: RemoveEmailDomainModal,
      hasModalCard: true,
      trapFocus: true,
      width: 550,
      onCancel: () => '',
      props: {
        notify: this.notify,
        domains: this.allowedEmailDomains,
        parentsName: this.parentsName,
        removeMembers: true,
        addFirstDomain: true
      },
      events: {
        submit: (notify: boolean) => {
          this.isAddingConfirmed = true;
          this.notify = notify;
          this.submitPayload();
        }
      }
    });
  }

  async handleUpdateGroup() {
    if (this.isSettings && this.isAddingFirstDomain) {
      this.confirmAdd();
    } else {
      await this.submitPayload();
    }
  }

  getUserAttributeArrayWithValue(): GroupUserAttributeWithValue[][] {
    if (this.isMemberTableVisible) {
      return this.membersData && this.userAttributes
        ? userAttributesArrWithValues(this.membersData, this.userAttributes)
        : [];
    } else {
      return [];
    }
  }

  public async submitPayload() {
    const rootValidationObserver = this.$refs
      .rootValidationObserver as ValidationObserverInstance;
    if ((await rootValidationObserver.validate()) === false) {
      const message = !this.name?.trim()
        ? 'Name is required'
        : !this.userAttributes?.length
        ? 'User Attributes are required'
        : !this.membersData?.length
        ? 'Member data is required'
        : 'Please fill in all the required fields';
      return DialogProgrammatic.alert({
        title: `Error`,
        message: `<p class="subtitle">${message}<p>`,
        // confirmText: 'Go Back',
        type: 'is-primary'
      });
    }

    try {
      const payload: UpdateGroupRequestPayload = {
        groupId: this.groupId,
        name: this.name,
        attributesSpecs: this.userAttributes,
        agreements: this.agreementFiles.map((f) => ({
          fileUri: (f as GetSignedUrlForUploadResponsePayload).storageUri,
          fileName: (f as GetSignedUrlForUploadResponsePayload).fileName
        })),
        userAttributesArr: this.getUserAttributeArrayWithValue(),
        parentPath: this.groupDetail?.path,
        types: this.types.map((t) => t.slug as MyjbiGroupType),
        emailDomains: this.allowedEmailDomains,
        removedEmailDomains:
          this.existingEmailDomains.length &&
          this.removedEmailDomains.length === this.existingEmailDomains.length
            ? [{ tag: null, notify: false }]
            : this.removedEmailDomains,
        emailDomainsNotify: this.notify,
        removedMemberIds: this.removedMemberIds
      };
      await this.updateGroup(payload);

      if (
        this.activeGroupAttributes.length ||
        this.deleteGroupAttributes.length
      ) {
        this.isGroupLevelAttributeLoading = true;
        await this.updateGroupLevelAttributeValues({
          groupId: this.groupId,
          attributeValues: {
            active: this.convertToAttributeOptionsWithValue(
              this.activeGroupAttributes
            ),
            deleted: this.convertToAttributeOptionsWithValue(
              this.deleteGroupAttributes
            )
          }
        });
        this.isGroupLevelAttributeLoading = false;
      }

      this.$router.push({
        name: 'admin-view-group',
        params: {
          groupId: String(this.groupId)
        }
      });
    } catch (error: any) {
      Toast.open({
        queue: true,
        type: 'is-danger',
        position: 'is-top',
        message: error?.response?.data?.message || error,
        duration: 5000
      });
    }
  }

  public async handleDeleteGroup() {
    const message = `Deleting this ${
      this.isMasterGroup ? 'master group' : 'subgroup'
    } will also delete its subgroup(s) and their access to product(s). This action cannot be undone.`;

    const dialogElem: Vue = DialogProgrammatic.confirm({
      message,
      title: `Delete ${this.groupDetail?.name}?`,
      cancelText: 'Delete',
      confirmText: 'Cancel',
      type: 'is-primary',
      canCancel: ['button'],
      onCancel: () => this.confirmDeleteGroup(),
      onConfirm: () => undefined
    });

    while (!dialogElem.$el?.querySelector) {
      await delay(50);
    }
    const cancelBtn = dialogElem.$el?.querySelector?.(
      `.modal-card > footer > button:first-child`
    );
    cancelBtn?.classList?.add?.(`is-red`);
  }

  public async confirmDeleteGroup() {
    try {
      await this.deleteGroup(this.groupDetail?.id!);
      if (this.parentGroupId) {
        this.$router.push({
          name: 'admin-view-group',
          params: { groupId: String(this.parentGroupId) }
        });
      } else {
        this.$router.push({
          name: 'admin-group-management',
          query: {
            tab: 'Groups'
          }
        });
      }
    } catch (error: any) {
      Toast.open({
        queue: true,
        type: 'is-danger',
        position: 'is-top',
        message: error?.response?.data?.message || error,
        duration: 5000
      });
    }
  }

  public async handleDownloadCsv() {
    this.isDownloading = true;

    if (!this.groupDetail) {
      this.isDownloading = false;
      return;
    }
    const result: ExportGroupMembersCsvPayload = {
      id: this.groupId,
      parentsName: this.groupDetail?.parentsName
    };
    await this.exportCsvGroupMembersList(result);
    this.isDownloading = false;
  }

  public transformData() {
    let result = null;
    const membersDataArray =
      cloneDeep(this.groupDetail?.members?.map((a) => a.attributes)) || null;

    if (membersDataArray) {
      result = membersDataArray.map((user: MemberObject) => {
        this.userAttributes.map((attribute) => {
          const { slug } = attribute.groupUserAttribute;
          user[slug] = {
            value: user[slug] ? user[slug] : null,
            isRequired: attribute.required ? true : false,
            isValid: attribute.required && !user[slug] ? false : true,
            errorMessage:
              attribute.required && !user[slug] ? 'This Field is Required' : ''
          };
        });
        return user;
      });
    }
    this.membersData = result;
  }

  convertToAttributeOptionsWithValue(
    attributes: GroupLevelAttributeValue[]
  ): AttributeOptionsWithValue[] {
    return attributes.map((attr) => ({
      groupLevelAttributeId: attr.id!, // Assertion that id exists (ensure validation)
      order: attr.groupLevelAttributeSpec.order,
      isRequired: attr.groupLevelAttributeSpec.isRequired,
      mapId: attr.mapId,
      groupLevelAttributeSpecId: attr.groupLevelAttributeSpec.id,
      status: attr.mapStatus,
      value: attr.value
    }));
  }

  get isAllowedToEditEmailDomainForm() {
    return this.isUserAllowed(
      PermissionsMatrixActionsEnum.UPDATE,
      'group_administration-groups-update_groups-update_email_domain',
      true
    );
  }

  @isDifferent
  @isTruthy
  @Watch('isDownloading')
  public handleDownloadMembersList() {
    Toast.open({
      queue: true,
      type: 'is-dark',
      position: 'is-top',
      message: `Downloading members list...`
    });
  }

  @Watch('userAttributes')
  onAttributeChange() {
    if (this.membersData) {
      this.membersData = this.membersData.map((user: MemberObject) => {
        const arrayOfKeys = Object.keys(user);
        const attributeArray = this.userAttributes.map(
          (attr) => attr.groupUserAttribute.slug
        );

        arrayOfKeys
          .filter((userKeys) => !attributeArray.includes(userKeys))
          .map((keys) => {
            delete user[keys];
          });

        this.userAttributes.map((attribute) => {
          const { slug, groupUserAttributeType } = attribute.groupUserAttribute;
          user[slug] = {
            value: user[slug]?.value ? user[slug].value : null,
            isRequired: attribute.required ? true : false,
            isValid: !(attribute.required && !user[slug]?.value),
            errorMessage:
              attribute.required && !user[slug]?.value
                ? 'This Field is Required'
                : ''
          };
          if (groupUserAttributeType.type === 'date') {
            user[slug].value = user[slug]?.value
              ? new Date(user[slug].value)
              : null;
          }
        });
        return user;
      });
    }
    this.membersFile = null;
  }

  @Watch('groupId', { immediate: true })
  @isDifferent
  @isTruthy
  async onParentGroupIdChange() {
    this.getGroupDetail({ id: this.groupId, isMembers: true });
    this.getParentGroupDetailByChildGroupId(this.groupId);
  }

  @Watch('groupDetail', { immediate: true })
  @isTruthy
  onGroupLoaded() {
    this.allowedEmailDomains = this.groupDetail?.emailDomains || [];
    this.existingEmailDomains = this.groupDetail?.emailDomains || [];
    this.initialDomains = this.allowedEmailDomains
      ? [...this.allowedEmailDomains]
      : [];
    this.name = this.groupDetail!.name;
    this.types =
      this.groupDetail?.types.map((t) => ({ slug: t, name: t })) || [];
    this.userAttributes = this.groupDetail?.groupUserAttributeSpecs
      ? this.groupDetail?.groupUserAttributeSpecs
      : [];
    this.agreementFiles = this.groupDetail!.agreements.map(
      (a) =>
        ({
          storageUrl: a.fileUrl,
          storageUri: a.fileUri,
          fileName: a.fileName
        } as Partial<GetSignedUrlForUploadResponsePayload>)
    );
    this.membersFile = null;
    this.transformData();
  }

  @Watch('getParentGroupDetailSuccess', { immediate: true })
  @isTruthy
  onParentGroupLoad() {
    this.parentUserAttributes =
      this.parentGroupDetailByChildGroupId?.groupUserAttributeSpecs || [];
  }

  @Watch('masterGroupEmailDomains')
  @isTruthy
  onMasterGroupEmailDomainsLoaded() {
    this.allowedEmailDomains = this.getEmailDomains();
    this.existingEmailDomains = this.getEmailDomains();
  }

  public mounted() {
    this.getGroupExceptions(this.groupId);
    if (
      this.isUserAllowed(
        PermissionsMatrixActionsEnum.READ,
        'group_administration-groups-read_groups-read_email_domain',
        true
      )
    ) {
      this.getMasterGroupEmailDomains(this.groupId);
    }
    this.allowedEmailDomains = this.getEmailDomains();
    this.existingEmailDomains = this.getEmailDomains();
    this.initialDomains = [...this.allowedEmailDomains];
  }
}
