











































































































import { Component, Vue, Watch } from 'vue-property-decorator';
import AddMembersFromFileModal from './components/AddMembersFromFileModal.vue';
import AddMembersFromListModal from './components/AddMembersFromListModal.vue';
import {
  CreateNewGroupRequestPayload,
  CreateNewGroupResponsePayload
} from '@/store/modules/admin/types/group.types';
import { Action, State } from 'vuex-class';
import Container from '@/components/Container.vue';
import BaseMultiSelect from '@/jbi-shared/vue-components/BaseMultiSelect.vue';
import { GetSignedUrlForUploadResponsePayload } from '@/store/modules/static-file/types/static-file.types';
import AgreementSection from '@/views/AdminCreateGroup/components/AgreementSection.vue';
import GroupCreationSubmitButtons from '@/views/AdminCreateGroup/components/GroupCreationSubmitButtons.vue';
import GroupAttributeForm from '@/views/AdminCreateGroup/components/GroupAttributeForm.vue';
import EmailDomainForm from '@/views/AdminCreateGroup/components/EmailDomainForm.vue';
import ProtectedGroupSection from '@/views/AdminCreateGroup/components/ProtectedGroupSection.vue';
import MembersUploadSection from '@/views/AdminCreateGroup/components/MembersUploadSection.vue';
import ExistingMembersSelector from '@/views/AdminCreateSubGroup/components/ExistingMembersSelector.vue';
import EditableMemberTable from '@/views/AdminCreateGroup/components/EditableMemberTable.vue';
import GroupLevelAttribute from '../AdminGroupSettings/components/GroupLevelAttribute.vue';
import GroupNameAndTypeForm from '../AdminCreateGroup/components/GroupNameAndTypeForm.vue';
import { DialogProgrammatic, ToastProgrammatic } from 'buefy';
import {
  ValidationObserver,
  ValidationProvider,
  ValidationObserverInstance
} from 'vee-validate';
import { RootState } from '@/store/store';
import {
  AddExistingMemberPayload,
  MemberObject,
  userAttributesArrWithValues,
  validatedGroupUserAttributeValue
} from '@/utils/group.util';
import {
  GetProtectedGroupTypesResponsePayload,
  GroupType
} from '@/store/modules/admin/types/admin.types';
import { UserAttributesStringInputOption } from '@/store/modules/admin/types/group.types';
import { GroupTemplateAttribute } from '@/store/modules/admin/types/group-level-attribute.types';
import {
  GroupUserAttributeTypeStatus,
  MyjbiGroupUserAttributeSpec
} from '@/jbi-shared/types/myjbi-group.types';
import UserAttributeTab from './components/UserAttributeTab/UserAttributeTab.vue';
import { ResourceExceptions } from '@/store/modules/roles-and-permissions/types/roles-and-permissions.types';
import {
  AttributeOptions,
  AttributeOptionsWithValue,
  GroupLevelAttributeValue
} from '@/jbi-shared/types/jaas-group-level-attributes.types';
import { ProtectedGroupTypes } from '@/jbi-shared/dto/jaas-groups.dtos';
import {
  CreateGroupLevelAttributeTemplateDTO,
  InsertGroupLevelAttributeValueDTO
} from '@/jbi-shared/dto/jaas-group-level-attributes.dtos';
import { isDefaultGroupAttribute } from '@/jbi-shared/util/group-level-attributes.util';
import { ApiState } from '@/store/types/general.types';
import { AxiosError } from 'axios';

export const defaultAttributes: MyjbiGroupUserAttributeSpec[] = [
  {
    groupUserAttribute: {
      id: 1,
      name: 'Email',
      slug: 'email',
      option: null,
      status: GroupUserAttributeTypeStatus.ACTIVE,
      groupUserAttributeType: {
        id: 2,
        type: 'email',
        option: null,
        status: GroupUserAttributeTypeStatus.ACTIVE
      }
    },
    required: true,
    isDefault: true
  },
  {
    groupUserAttribute: {
      id: 2,
      name: 'First Name',
      slug: 'first-name',
      option: null,
      status: GroupUserAttributeTypeStatus.ACTIVE,
      groupUserAttributeType: {
        id: 1,
        type: 'text',
        option: null,
        status: GroupUserAttributeTypeStatus.ACTIVE
      }
    },
    required: true,
    isDefault: true
  },
  {
    groupUserAttribute: {
      id: 3,
      name: 'Last Name',
      slug: 'last-name',
      option: null,
      status: GroupUserAttributeTypeStatus.ACTIVE,
      groupUserAttributeType: {
        id: 1,
        type: 'text',
        option: null,
        status: GroupUserAttributeTypeStatus.ACTIVE
      }
    },
    required: true,
    isDefault: true
  }
];

export const emailDomain = [];

@Component({
  components: {
    Container,
    BaseMultiSelect,
    AgreementSection,
    ValidationObserver,
    ValidationProvider,
    GroupAttributeForm,
    GroupNameAndTypeForm,
    EmailDomainForm,
    GroupCreationSubmitButtons,
    MembersUploadSection,
    ExistingMembersSelector,
    EditableMemberTable,
    ProtectedGroupSection,
    GroupLevelAttribute,
    UserAttributeTab
  }
})
export default class AdminCreateGroup extends Vue {
  public name = '';
  public type: UserAttributesStringInputOption | null = null;
  public protectedTypes: ProtectedGroupTypes[] = [];
  public userAttributes: MyjbiGroupUserAttributeSpec[] = [...defaultAttributes];
  public agreementFiles: Array<
    File | GetSignedUrlForUploadResponsePayload
  > = [];
  public membersFile: File | null = null;
  public membersData: MemberObject[] = [];
  public notify = false;
  public addMembersModal: any = null;
  public allowedEmailDomains: string[] = [];
  public createGroupLoading = false;
  public isMemberDataValid = true;
  public selectedProtectedGroupData: ProtectedGroupTypes | null = null;
  public protectGroup: boolean = false;

  currentTab: number = 0;
  activeGroupLevelAttributes: GroupLevelAttributeValue[] = [];
  deletedGroupLevelAttributes: GroupLevelAttributeValue[] = [];
  groupLevelAttributeFormHasError: boolean = false;

  saveAsTemplate: boolean = false;
  templateName: string = '';

  /* Actions and States: Create Group */
  @Action('admin/createNewGroup')
  createNewGroup!: (
    payload: CreateNewGroupRequestPayload
  ) => Promise<CreateNewGroupResponsePayload>;

  @State(({ admin }: RootState) => admin.apiState.createNewGroup)
  createNewGroupApiState!: ApiState;

  /* Actions and States: Group Level Attribute Values */
  @Action('admin/createGroupLevelAttributesValues')
  createGroupLevelAttributeValues!: (
    payload: InsertGroupLevelAttributeValueDTO
  ) => Promise<void>;

  @State(
    ({ admin }: RootState) => admin.apiState.createGroupLevelAttributeValues
  )
  createGroupLevelAttributeValuesApiState!: ApiState;

  /* Actions and States: Group Types */
  @Action('admin/getGroupTypes')
  getGroupTypes!: () => void;

  @Action('admin/getProtectedGroupTypes')
  getProtectedGroupTypes!: () => void;

  @State((state: RootState) => state.admin.protectedGroupTypes)
  protectedGroupTypes!: GetProtectedGroupTypesResponsePayload;

  @State(({ admin }: RootState) => admin.groupTypes)
  groupTypes!: GroupType[];

  /* Actions and States: Group Level Attribute Templates */
  @Action('admin/createGroupLevelAttributeTemplate')
  createGroupLevelAttributeTemplate!: (
    payload: CreateGroupLevelAttributeTemplateDTO
  ) => void;

  @State(
    ({ admin }: RootState) => admin.apiState.createGroupLevelAttributeTemplate
  )
  createGroupLevelAttributeTemplateApiState!: ApiState;

  @State(
    (state: RootState) => state.admin.verifyGroupLevelAttributeTemplateName
  )
  isDuplicateTemplateName!: boolean;

  /* Actions and States: Group Exceptions */
  @State((state: RootState) => state.rolesAndPermissions.groupExceptions)
  public groupExceptions!: ResourceExceptions;

  /* Computed Properties */
  get isLocalOrDev() {
    return (
      process.env.VUE_APP_ENV === 'local' ||
      process.env.VUE_APP_ENV === 'development'
    );
  }

  get typeOptions(): UserAttributesStringInputOption[] {
    return Object.values(this.groupTypes).map((groupType: GroupType) => ({
      id: groupType.id,
      slug: groupType.name,
      name: groupType.name
    }));
  }

  get groupId() {
    return this.$route.params.groupId;
  }

  get isLoadingActive(): boolean {
    return (
      !this.groupTypes ||
      this.createNewGroupApiState.loading ||
      this.createGroupLevelAttributeTemplateApiState.loading ||
      this.createGroupLevelAttributeValuesApiState.loading
    );
  }

  get activeNonDefaultAttributeCount(): number {
    return this.activeGroupLevelAttributes.filter(
      (attribute) => !isDefaultGroupAttribute(attribute)
    ).length;
  }

  created() {
    this.getProtectedGroupTypes();
    this.getGroupTypes();
    this.allowedEmailDomains = [];
  }

  groupCreationFormHasError(
    isValidationObserverInvalid: boolean,
    isValidationObserverValidated: boolean
  ): boolean {
    return (
      !this.isMemberDataValid ||
      isValidationObserverInvalid ||
      !isValidationObserverValidated ||
      this.groupLevelAttributeFormHasError
    );
  }

  public handleAddExistingMember() {
    this.$buefy.modal.open({
      parent: this,
      component: AddMembersFromListModal,
      hasModalCard: true,
      trapFocus: true,
      canCancel: false,
      props: {
        newGroup: true,
        userAttributes: this.userAttributes,
        existingMembers: this.membersData,
        allowedEmailDomains: this.allowedEmailDomains
      },
      events: {
        add: (payload: AddExistingMemberPayload) => {
          const { data } = payload;
          this.membersData = [...this.membersData, ...data];
        }
      }
    });
  }

  public handleRemoveMember(index: number) {
    this.membersData = this.membersData.splice(index, 1);
  }

  public handleAddMembersFromFile() {
    this.addMembersModal = this.$buefy.modal.open({
      parent: this,
      component: AddMembersFromFileModal,
      hasModalCard: true,
      trapFocus: true,
      canCancel: false,
      props: {
        userAttributes: this.userAttributes,
        existingMembers: this.membersData,
        allowedEmailDomains: this.allowedEmailDomains
      },
      events: {
        add: (data: MemberObject[]) => {
          this.membersData = [...this.membersData, ...data];
          this.addMembersModal.close();
        }
      }
    });
  }

  public handleAddEmailDomains(domain: string) {
    this.allowedEmailDomains.push(domain);
  }

  public handleRemoveEmailDomain(tag: any) {
    const matchedIndex: number = this.allowedEmailDomains.indexOf(tag.tag);
    this.allowedEmailDomains.splice(matchedIndex, 1);
  }

  public async handleCreateGroup() {
    this.createGroupLoading = true;
    await this.createGroup();
  }

  /*
   * "activeGroupLevelAttributes" is an array of group level attributes with values.
   * This method converts active group level attributes into attribute options, excluding default
   * attributes. Order the non-default attributes sequentially starting from index 1.
   */
  getGroupLevelAttributesAsAttributeOptions(): AttributeOptions[] {
    return this.activeGroupLevelAttributes
      .filter((attribute: GroupLevelAttributeValue) => {
        return !isDefaultGroupAttribute(attribute);
      })
      .map((nonDefaultAttributes: GroupLevelAttributeValue, index: number) => {
        return {
          groupLevelAttributeId: nonDefaultAttributes.id!,
          order: index + 1,
          isRequired: nonDefaultAttributes.groupLevelAttributeSpec.isRequired
        };
      });
  }

  handleUpdateTemplateDetails(saveAsTemplate: boolean, templateName: string) {
    this.saveAsTemplate = saveAsTemplate;
    this.templateName = templateName;
  }

  async createGroup() {
    const rootValidationObserver = this.$refs
      .rootValidationObserver as ValidationObserverInstance;

    if ((await rootValidationObserver.validate()) === false) {
      const message = !this.name?.trim()
        ? 'Name is required'
        : !this.userAttributes?.length
        ? 'User Attributes are required'
        : !this.membersData?.length
        ? 'Member data is required'
        : !this.type
        ? 'Group type is required'
        : 'Please fill in all the required fields';

      return DialogProgrammatic.alert({
        title: `Error`,
        message: `<p class="subtitle">${message}<p>`,
        type: 'is-primary'
      });
    }
    try {
      // TODO: unify group and gla values payload, it's hella cringe rn
      this.sanitizeMembersData();
      const payload: CreateNewGroupRequestPayload = {
        name: this.name,
        attributesSpecs: this.userAttributes,
        agreements: this.agreementFiles.map((file) => ({
          fileUri: (file as GetSignedUrlForUploadResponsePayload).storageUri,
          fileName: (file as GetSignedUrlForUploadResponsePayload).fileName
        })),
        userAttributesArr: userAttributesArrWithValues(
          this.membersData,
          this.userAttributes
        ),
        types: [this.type!.slug],
        notify: this.notify,
        emailDomains: this.allowedEmailDomains,
        protectedGroup: {
          isProtected: this.protectGroup,
          protectedGroupType: this.selectedProtectedGroupData
        }
      };
      const { id } = await this.createNewGroup(payload);

      const glaValuesPayload: InsertGroupLevelAttributeValueDTO = {
        groupId: id,
        attributeValues: this.convertToAttributeOptionsWithValue(
          this.activeGroupLevelAttributes
        )
      };

      if (this.activeGroupLevelAttributes.length) {
        await this.createGroupLevelAttributeValues(glaValuesPayload);
      }

      if (
        !this.isDuplicateTemplateName &&
        this.saveAsTemplate &&
        this.templateName
      ) {
        this.createGLATemplateConfirmation();
      }

      // FIXME: This should be moved to the watcher, when payload is unified
      ToastProgrammatic.open({
        queue: true,
        type: 'is-dark',
        position: 'is-top',
        message: `Group created`
      });

      // FIXME: same here
      this.$router.push({
        name: 'admin-view-group',
        params: { groupId: String(id) }
      });
    } catch (error: any) {
      ToastProgrammatic.open({
        queue: true,
        type: 'is-danger',
        position: 'is-top',
        message: (error as AxiosError).response?.data.message || error,
        duration: 5000
      });
    }
  }

  handleCreateGLATemplate() {
    this.createGroupLevelAttributeTemplate({
      templateName: this.templateName,
      attributes: this.getGroupLevelAttributesAsAttributeOptions()
    });
  }

  createGLATemplateConfirmation(): void {
    const title = 'Create New Attribute Template?';
    const content = `${this.activeGroupLevelAttributes.length} attributes (excluding default attributes) will be added as custom template. Continue?`;
    const dialogMessage = `
          <p class="buefy-dialog-title">${title}</p>
          <p class="buefy-dialog-content">${content}</p>
        `;

    this.$buefy.dialog.confirm({
      message: dialogMessage,
      confirmText: 'Save',
      onConfirm: this.handleCreateGLATemplate,
      canCancel: ['button'],
      cancelText: 'Cancel',
      onCancel: undefined
    });
  }

  /**
   * This method converts group level attributes with values to attribute options
   * @param attributes Group level attributes with values
   */
  convertToAttributeOptionsWithValue(
    attributes: GroupLevelAttributeValue[]
  ): AttributeOptionsWithValue[] {
    return attributes.map((attr) => ({
      groupLevelAttributeId: attr.id!, // Assertion that id exists (ensure validation)
      order: attr.groupLevelAttributeSpec.order,
      isRequired: attr.groupLevelAttributeSpec.isRequired,
      mapId: attr.mapId,
      groupLevelAttributeSpecId: attr.groupLevelAttributeSpec.id,
      status: attr.mapStatus,
      value: attr.value
    }));
  }

  @Watch('createGroupLevelAttributeTemplateApiState', { immediate: true })
  handleCreateGroupLevelAttributeTemplateApiStateChange() {
    if (
      this.createGroupLevelAttributeTemplateApiState.success &&
      this.saveAsTemplate
    ) {
      ToastProgrammatic.open({
        queue: true,
        type: 'is-dark',
        position: 'is-top',
        message: `Group level attribute template created`
      });
    }
  }

  @Watch('userAttributes')
  onAttributeChange() {
    this.membersData = this.membersData.map((user: MemberObject) => {
      this.userAttributes.map((attribute: MyjbiGroupUserAttributeSpec) => {
        const { slug } = attribute.groupUserAttribute;
        user[slug] = validatedGroupUserAttributeValue(
          attribute,
          user,
          this.allowedEmailDomains
        );
      });
      return user;
    });
  }

  handleUpdateGroupLevelAttributeValues(
    activeValues: GroupLevelAttributeValue[],
    deleteValues: GroupLevelAttributeValue[],
    error: boolean
  ) {
    this.activeGroupLevelAttributes = activeValues;
    this.deletedGroupLevelAttributes = deleteValues;
    this.groupLevelAttributeFormHasError = error;
  }

  public sanitizeMembersData() {
    this.membersData = this.membersData.map((user: MemberObject) => {
      const arrayOfKeys = Object.keys(user);
      const attributeArray = this.userAttributes.map(
        (userAttr) => userAttr.groupUserAttribute.slug
      );

      arrayOfKeys
        .filter((userKeys) => !attributeArray.includes(userKeys))
        .map((keys) => {
          delete user[keys];
        });
      return user;
    });
  }
}
