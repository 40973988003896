var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ValidationObserver',{ref:"rootValidationObserver",attrs:{"slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
var validationErrors = ref.errors;
return [_c('Container',{attrs:{"title":"Group Settings"},scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('h1',[_vm._v("Settings "),(_vm.isProtected)?_c('i',{staticClass:"fa fa-lock"}):_vm._e()])]},proxy:true}],null,true)},[_c('b-loading',{attrs:{"is-full-page":true,"active":_vm.pageIsLoading}}),(_vm.groupDetail)?[_c('div',{staticClass:"box"},[(
            _vm.isUserAllowed(
              _vm.PermissionsMatrixActionsEnum.UPDATE,
              'group_administration-groups-update_groups-update_group_name',
              true
            )
          )?_c('GroupNameAndTypeForm',{attrs:{"name":_vm.name,"types":_vm.types},on:{"update:name":function($event){_vm.name=$event},"update:types":function($event){_vm.types=$event}}}):_vm._e(),_c('b-tabs',{model:{value:(_vm.currentTab),callback:function ($$v) {_vm.currentTab=$$v},expression:"currentTab"}},[_c('b-tab-item',{attrs:{"label":"User Attributes"}},[(_vm.isUserLevelAttribute)?_c('UserAttributeTab',{attrs:{"userAttributes":_vm.userAttributes,"name":_vm.name,"type":_vm.types[0],"parentAttributes":_vm.parentUserAttributes,"isSettings":_vm.isSettings,"groupId":_vm.groupId,"groupTypeName":_vm.groupDetail.types[0],"group-exceptions":_vm.groupExceptions},on:{"update:userAttributes":function($event){_vm.userAttributes=$event},"update:user-attributes":function($event){_vm.userAttributes=$event}}}):_vm._e(),(
                _vm.isUserAllowed(
                  _vm.PermissionsMatrixActionsEnum.READ,
                  'group_administration-groups-read_groups-read_email_domain',
                  true
                )
              )?_c('EmailDomainForm',{attrs:{"allowedEmailDomains":_vm.allowedEmailDomains,"initialDomains":_vm.initialDomains,"isMaster":_vm.isMasterGroup,"isSettings":_vm.isSettings,"parentsName":_vm.parentsName,"is-allowed-to-edit":_vm.isAllowedToEditEmailDomainForm},on:{"addEmailDomain":_vm.handleAddEmailDomain,"removeEmailDomain":_vm.handleRemoveEmailDomain}}):_vm._e(),(_vm.isMemberTableVisible)?_c('GroupSettingsMembersSection',{attrs:{"showMemberLabel":"","shouldDownloadExistingMemberInCsv":"","membersData":_vm.membersData,"userAttributes":_vm.userAttributes,"membersFile":_vm.membersFile,"validationErrors":validationErrors,"csvFileName":("existing-member-data_" + _vm.groupName),"isMemberDataValid":_vm.isMemberDataValid,"allowedEmailDomains":_vm.allowedEmailDomains,"isSettings":_vm.isSettings,"groupId":_vm.groupId,"groupTypeName":_vm.groupDetail.types[0],"group-exceptions":_vm.groupExceptions},on:{"update:membersData":function($event){_vm.membersData=$event},"update:members-data":function($event){_vm.membersData=$event},"update:membersFile":function($event){_vm.membersFile=$event},"update:members-file":function($event){_vm.membersFile=$event},"update:isMemberDataValid":function($event){_vm.isMemberDataValid=$event},"update:is-member-data-valid":function($event){_vm.isMemberDataValid=$event},"removedMembers":_vm.removedMembers,"clear":function($event){_vm.membersData = null;
                _vm.membersFile = null;}}}):_vm._e(),(
                _vm.hasSameAttributesAsParent &&
                !_vm.attributesHasChanged &&
                _vm.groupDetailMembers.length > 0 &&
                _vm.isUserAllowed(
                  _vm.PermissionsMatrixActionsEnum.READ,
                  'group_administration-groups-read_groups-read_members-read_members_list',
                  true
                )
              )?_c('div',{class:_vm.$style.csvDownloadButtonContainer,on:{"click":_vm.handleDownloadCsv}},[_c('p',[_vm._v("Download Members List (CSV)")])]):_vm._e(),(
                _vm.isUserAllowed(
                  _vm.PermissionsMatrixActionsEnum.READ,
                  'group_administration-groups-read_groups-read_supporting_documents',
                  true
                )
              )?_c('AgreementSection',{attrs:{"agreementFiles":_vm.agreementFiles,"isSettings":_vm.isSettings,"groupId":_vm.groupId,"groupTypeName":_vm.groupDetail.types[0],"group-exceptions":_vm.groupExceptions},on:{"update:agreementFiles":function($event){_vm.agreementFiles=$event},"update:agreement-files":function($event){_vm.agreementFiles=$event}}}):_vm._e(),_c('div',{staticClass:"is-divider"}),(
                _vm.isUserAllowed(
                  _vm.PermissionsMatrixActionsEnum.DELETE,
                  'group_administration-groups-delete_groups',
                  true
                )
              )?_c('a',{staticClass:"has-text-danger has-text-weight-bold",class:_vm.$style.deleteMasterGroup,on:{"click":_vm.handleDeleteGroup}},[_vm._v(" Delete "+_vm._s(_vm.isMasterGroup ? 'Master Group' : 'Subgroup')+" ")]):_vm._e()],1),(
              _vm.isUserAllowed(
                _vm.PermissionsMatrixActionsEnum.UPDATE,
                'group_administration-groups-update_groups-update_group_attributes'
              )
            )?_c('b-tab-item',{attrs:{"label":"Group Attributes"}},[(_vm.isGroupLevelAttribute)?_c('GroupLevelAttribute',{attrs:{"groupId":_vm.groupId,"groupName":_vm.name,"isSettings":_vm.isSettings,"groupTypeName":_vm.groupDetail.types[0],"isGroupLevelAttributeTabOpened":_vm.currentTab === 1,"group-exceptions":_vm.groupExceptions},on:{"updateValuesAndError":_vm.handleUpdateGroupLevelAttributeValues}}):_vm._e()],1):_vm._e()],1),_c('GroupCreationSubmitButtons',{attrs:{"loading":_vm.updateGroupLoading,"invalid":_vm.groupSettingsHasError(invalid),"btnText":"Save"},on:{"submit":_vm.handleUpdateGroup}})],1)]:_vm._e()],2)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }